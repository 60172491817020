var render = function () {
var _obj;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"customindex",class:( _obj = {}, _obj[_vm.$style.previewBox] = _vm.isPreview, _obj )},_vm._l((_vm.trueComponentList),function(item){
var _obj;
return _c('div',{key:item.id,class:( _obj = {
			draggableSlot: item.$$slot,
			draggableItem: !item.$$slot
		}, _obj[("draggableSlot_" + (item.$$slot))] = item.$$slot, _obj ),attrs:{"slot":item.$$slot || 'default'},slot:item.$$slot || 'default'},[_c('ViewComponentWrap',{attrs:{"editor-item":item,"is-preview":_vm.isPreview}},[_c(item.componentViewName,{tag:"component",attrs:{"slot":"componentView","form-data":item.componentValue},slot:"componentView"})],1)],1)}),0)}
var staticRenderFns = []

export { render, staticRenderFns }