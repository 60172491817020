<template>

	<div v-loading="loading" class="customindex" :class="{[$style.previewBox]: isPreview}">
		<div v-for="item in trueComponentList" :key="item.id" :slot="item.$$slot || 'default' "
			:class="{
				draggableSlot: item.$$slot,
				draggableItem: !item.$$slot,
				[`draggableSlot_${item.$$slot}`]: item.$$slot
			}">
			<ViewComponentWrap :editor-item="item" :is-preview="isPreview" >
				<component :is="item.componentViewName" slot="componentView" :form-data="item.componentValue"> </component>
			</ViewComponentWrap>
		</div>
						
	</div>
</template>

<script>
	import Draggable from 'vuedraggable';
	import VueElementForm, { schemaValidate	} from '@lljj/vue-json-schema-form';

	import ViewComponentWrap from '../edit/editor/components/ViewComponentWrap.vue';

	import {vm2Api,api2VmToolItem} from '../edit/editor/data.js';

	import configTools from '../edit/editor/config/tools.js';
	import configDefaultItems from '../edit/editor/config/defaultItems';

	import {getComponentsAndInitToolsConfig} from '../edit/editor/common/utils';
	import {generateEditorItem} from '../edit/editor/common/editorData';

	
	// 工具栏配置的组件
	const components = getComponentsAndInitToolsConfig(configTools);


	export default {
		name: 'Editor',
		components: {
			...components,
			ViewComponentWrap
		},
		data() {
			return {
				loading: false,
				isPreview: true,
				configTools,
				scale: 100,
				editComponentList: [],
				editHeaderComponentList: [], // 兼容header slot ，插件内部实现导致必须分割多分数据
				editFooterComponentList: [], // 兼容footer slot ，插件内部实现导致必须分割多分数据
				showToolBar: true,
			};
		},

		computed: {
			dragOptions() {
				return {
					animation: 300,
					group: 'listComponentsGroup',
					disabled: this.isPreview,
					ghostClass: this.$style.ghost,
					filter: this.$style.disabled,
					draggable: '.draggableItem',
					tag: 'div',
					swapThreshold: 0.3,
					// forceFallback: true
					// fallbackTolerance: 0
				};
			},
			// 头部、中间、底部各个list集合
			componentListGroup() {
				return [this.editHeaderComponentList, this.editComponentList, this.editFooterComponentList];
			},

			// 真实使用的组件 - 包含顶部、底部、不可拖动的模块平铺
			trueComponentList() {
				console.log('this.componentListGroup', this.componentListGroup)
				return [].concat(...this.componentListGroup);
			},

			// 计算出各个模块当前的使用数量
			currentUseComponentNum() {
				return this.trueComponentList.reduce((preVal, curVal) => {
					preVal[curVal.componentViewName] = preVal[curVal.componentViewName] ?
						(preVal[curVal.componentViewName] + 1) :
						1;
					return preVal;
				}, {});
			}
		},
		
		destroyed() {
			
			window.document.body.classList.remove('page-decorate-design');
		},
		created() {
			this.initEditorData();
			let trueComponentList=sessionStorage.getItem('ComponentList')
			console.log('configDefaultItems',configDefaultItems)
		},
		
		methods: {
			async initEditorData() {
				// 使用默认值
				let ComponentList=JSON.parse(sessionStorage.getItem('ComponentList'))
				console.log('ComponentList',ComponentList)
				const dataList = api2VmToolItem(configTools, ComponentList||configDefaultItems);
				// 重新插入数据
				dataList.forEach((toolItemData) => {
					if (!toolItemData.componentPack) {
						console.warn('存在一条异常数据，请检查：');
						console.log(dataList);
						return;
					}
					const editorData = generateEditorItem(toolItemData);
					
					// 模拟拖入组件插入数据
					this.editComponentList.push(editorData);
					if (editorData.additional) {
						// 新加的元素处理特殊配置信息
						// this.additionalStrategy(editorData.additional, editorData);
					}
				});
			},

			
		}
	};
</script>

<style>
	.customindex .text_container_1tyH_{height: auto;}
	.customindex .text_hasTools_3wZcK{padding-left: 0;}
	.customindex .text_contentBox_3xpar{width: auto;}
	.customindex .ViewComponentWrap_viewBox_3Rldo{cursor: auto}
	.flip-list-move {transition: transform 0.3s}
	.no-move {transition: transform 0s;}
</style>
<style module>
	@import '../edit/demo-common/css/variable.css';

	:root {
		--site-top-height: 80px;
		--tool-bar-width: 260px;
		/* --drag-area-width: 1900px; */
	}

	/*预览模式 同步样式重置*/
	.previewBox {

		.toolsBar,
		.leftCaret {
			display: none;
		}

		.container {
			height: 100vh;
			padding-left: 0;
		}

		.dragAreaWrap {
			overflow-x: hidden;
		}

		.contentBox, .dragAreaWrap {width: auto;}

		/* :global {
			.vueEditor_viewComponentBox {
				margin-left: 50%;
				transform: translate(-50%, 0);
				cursor: auto;
				box-shadow: none;

				&:after {
					display: none;
					content: none;
				}
			}
		} */
	}

	.container {
		position: relative;
		box-sizing: border-box;
		padding-left: 0;
		height: calc(100vh - var(--site-top-height));
		transition: 0.2s ease;
	}

	.hasTools {
		padding-left: var(--tool-bar-width);

		:global .el-icon-caret-right {
			transform: rotate(180deg);
		}
	}

	/*tools*/
	.leftCaret {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		position: absolute;
		width: 18px;
		height: 50px;
		background: var(--color-white);
		top: 50%;
		margin-top: -25px;
		box-shadow: 0 0 4px 0 color(var(--color-black) a(0.1));
		transition: all ease 0.3s;
		border-radius: 0 10px 10px 0;
		z-index: 9;

		&:hover {
			box-shadow: 0 0 4px 0 color(var(--color-black) a(0.2));
			opacity: 1;
		}
	}

	.toolsBar {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		background: var(--color-white);
		width: var(--tool-bar-width);
		overflow: auto;
		box-shadow: 0 0 4px 0 color(var(--color-black) a(0.2));
		z-index: 2;

		&::-webkit-scrollbar {
			width: 0;
			height: 0;
		}
	}

	/*content area*/
	.contentWrap {
		position: relative;
		overflow: auto;
		height: 100%;

		&::-webkit-scrollbar {
			width: 6px;
			height: 10px;
		}

		&::-webkit-scrollbar-track {
			background-color: var(--background-color-base);
		}

		&::-webkit-scrollbar-thumb {
			border-radius: 10px;
			background-color: var(--color-text-placeholder);
		}
	}

	.contentBox {
		position: relative;
		/* width: 2000px; */
		min-height: 100%;
	}

	.dragAreaWrap {
		transform-origin: top left;
		position: relative;
		width: var(--drag-area-width);
		margin: 0 20px 0;
		overflow: auto;
	}

	.tipBox {
		pointer-events: none;
		top: 20px;
		position: absolute;
		left: 0;
		width: 100%;
		text-align: center;
		margin: 30vh 0;

		p {
			margin: 20px 0;
			font-size: 16px;
		}
	}

	.dragArea {
		/* min-height: calc(100vh - var(--site-top-height) - 50px); */
		min-height: calc(100vh + 100px);
		background-color: #f5f5f5;
		padding: 5px 0;

		:global {
			.draggableToolItem {
				width: 100%;
				max-width: 100%;

				&:local {
					&.ghost {
						background-color: color(var(--color-primary) a(0.4)) !important;
						box-shadow: 0 3px 14px 3px color(var(--color-primary) a(0.6)), 0 10px 10px 1px color(var(--color-primary) a(0.5));
						height: 120px !important;
						padding: 20px;

						&>div {
							width: 100%;
							height: 100%;
							background-color: var(--color-white);
						}

						p {
							font-size: 16px;
							line-height: 24px;
						}
					}
				}
			}

			.emptyBox {
				min-height: 350px;
				display: flex;
				justify-content: center;
				align-items: center;
			}

			.viewEmpty_IconBox {
				color: color(var(--checkbox-color) a(0.7));
				font-size: 50px;
				text-align: center;
			}

			.el-image {
				vertical-align: top;
			}
		}
	}

	.ghost {
		opacity: 0.5;
	}
</style>
